
import {
  defineComponent,
  onMounted,
  reactive,
  SetupContext,
  toRefs,
} from 'vue';
import sessionModule from '@/modules/auth/sessionModule';
import resource from '@/modules/resource';
import validation from '@/modules/validation';
import { updateNextOfKin } from '@/modules/requests';
import { AxiosError, AxiosResponse } from 'axios';
import { useStore } from 'vuex';

export default defineComponent({
  emits: ['success'],
  setup: (_props, { emit }: SetupContext) => {
    const { mapProfileData, genderOptions, relationshipOptions } =
      sessionModule();
    const { basicForm, getFormData } = resource();
    const { validateForm } = validation();
    const { dispatch } = useStore();

    const data = reactive({
      form: basicForm([
        {
          name: 'next_of_kin_name',
          rules: 'required',
        },
        {
          name: 'next_of_kin_email',
          rules: 'required|email',
        },
        {
          name: 'next_of_kin_mobile',
          rules: 'required|phone',
        },

        {
          name: 'next_of_kin_relation',
          rules: 'required',
        },
        {
          name: 'next_of_kin_gender',
          rules: 'required',
        },
      ]),
    });

    onMounted(() => mapProfileData(data.form));

    const submit = async (): Promise<any> => {
      if (!validateForm(data.form)) return false;
      data.form.loading = true;
      await updateNextOfKin(getFormData(data.form))
        .then((response: AxiosResponse) => {
          dispatch('updateUser', response?.data).then(() => emit('success'));
        })
        .catch((error: AxiosError) => console.log(error))
        .finally(() => (data.form.loading = false));
    };

    return {
      submit,
      ...toRefs(data),
      genderOptions,
      relationshipOptions,
    };
  },
});
