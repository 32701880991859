<template>
  <form @submit.prevent="submit">
    <form-input
      name="next_of_kin_name"
      :form="form"
      v-model="form.data.next_of_kin_name.value"
    >
      Full name
    </form-input>

    <form-input
      name="next_of_kin_email"
      :form="form"
      v-model="form.data.next_of_kin_email.value"
    >
      Email Address
    </form-input>
    <form-input
      name="next_of_kin_mobile"
      :form="form"
      v-model="form.data.next_of_kin_mobile.value"
    >
      Phone number
    </form-input>
    <form-input
      type="select"
      :form="form"
      name="next_of_kin_relation"
      :options="relationshipOptions"
      :defaultValue="`${form.data.next_of_kin_relation.value || ''}`"
      v-model="form.data.next_of_kin_relation.value"
    >
      Relationship
    </form-input>
    <form-input
      type="select"
      :form="form"
      name="next_of_kin_relation"
      :options="genderOptions"
      :defaultValue="`${form.data.next_of_kin_gender.value || ''}`"
      v-model="form.data.next_of_kin_gender.value"
    >
      Gender
    </form-input>
    <div class="mt-8">
      <button type="submit" class="btn btn-primary" :disabled="form.loading">
        <loader v-if="form.loading" />
        <span v-else>Submit</span>
      </button>
    </div>
  </form>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  reactive,
  SetupContext,
  toRefs,
} from 'vue';
import sessionModule from '@/modules/auth/sessionModule';
import resource from '@/modules/resource';
import validation from '@/modules/validation';
import { updateNextOfKin } from '@/modules/requests';
import { AxiosError, AxiosResponse } from 'axios';
import { useStore } from 'vuex';

export default defineComponent({
  emits: ['success'],
  setup: (_props, { emit }: SetupContext) => {
    const { mapProfileData, genderOptions, relationshipOptions } =
      sessionModule();
    const { basicForm, getFormData } = resource();
    const { validateForm } = validation();
    const { dispatch } = useStore();

    const data = reactive({
      form: basicForm([
        {
          name: 'next_of_kin_name',
          rules: 'required',
        },
        {
          name: 'next_of_kin_email',
          rules: 'required|email',
        },
        {
          name: 'next_of_kin_mobile',
          rules: 'required|phone',
        },

        {
          name: 'next_of_kin_relation',
          rules: 'required',
        },
        {
          name: 'next_of_kin_gender',
          rules: 'required',
        },
      ]),
    });

    onMounted(() => mapProfileData(data.form));

    const submit = async (): Promise<any> => {
      if (!validateForm(data.form)) return false;
      data.form.loading = true;
      await updateNextOfKin(getFormData(data.form))
        .then((response: AxiosResponse) => {
          dispatch('updateUser', response?.data).then(() => emit('success'));
        })
        .catch((error: AxiosError) => console.log(error))
        .finally(() => (data.form.loading = false));
    };

    return {
      submit,
      ...toRefs(data),
      genderOptions,
      relationshipOptions,
    };
  },
});
</script>
